import { getImageDimensions } from "@sanity/asset-utils";
import { type VariantProps } from "cva";
import NextImage, { type ImageProps as NextImageProps } from "next/image";

import { cva, cx, isDefinedAndNotEmpty } from "@/lib/utils";
import { resolveSanityImage } from "@/sanity/lib/utils";
import { type SanityAccessibleImage } from "@/sanity/schemas/types/accessible-image";

export type ImageCommonProps = VariantProps<typeof variants> &
  VariantProps<typeof imageVariants> & {
    width?: number;
    height?: number;
    caption?: string;
  };

export type ImagePropsWithImage = Omit<NextImageProps, "src" | "alt"> & {
  image: SanityAccessibleImage;
  src?: never;
  alt?: never;
};

export type ImagePropsWithSrcAlt = NextImageProps & {
  image?: never;
};

export type ImageProps = ImageCommonProps & (ImagePropsWithImage | ImagePropsWithSrcAlt);

export const variants = cva({
  base: "relative h-full w-full",
  variants: {
    fill: {
      true: "aspect-video",
      false: "aspect-auto",
    },
  },
});

export const imageVariants = cva({
  base: "block h-full w-full",
  variants: {
    fit: {
      cover: "object-cover object-center",
      contain: "w-fit object-contain object-left",
    },
  },
  defaultVariants: {
    fit: "cover",
  },
});

export function Image({ image, alt, caption, fill, fit, className, ...props }: ImageProps) {
  let { src, width, height } = props;

  if (isDefinedAndNotEmpty(image)) {
    let resolvedImage = resolveSanityImage(image);

    if (isDefinedAndNotEmpty(width)) resolvedImage = resolvedImage.width(width);
    if (isDefinedAndNotEmpty(height)) resolvedImage = resolvedImage.height(height);

    const resolvedImageUrl = resolvedImage.url();
    const imageDimensions = getImageDimensions(resolvedImageUrl);

    src = resolvedImageUrl;
    width = width || imageDimensions.width;
    height = height || imageDimensions.height;
  }

  return (
    isDefinedAndNotEmpty(src) && (
      <figure className="flex h-full w-full flex-col items-center justify-center">
        <div className={cx(variants({ fill }), className)}>
          <NextImage
            src={src}
            alt={alt || caption || ""}
            className={cx(imageVariants({ fit }))}
            sizes="(max-width: 768px) 100vw, 50vw"
            {...(!isDefinedAndNotEmpty(fill) && {
              width: width,
              height: height,
            })}
            {...props}
          />
        </div>
        {caption && <figcaption className="ml-0 mr-auto mt-4 text-sm">{caption}</figcaption>}
      </figure>
    )
  );
}
