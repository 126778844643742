"use client";

import { IconMenu, IconX } from "@tabler/icons-react";
import { type VariantProps } from "cva";
import { type HTMLMotionProps, motion, type Variants } from "framer-motion";
import { type HTMLAttributes } from "react";

import { TabBarButton, type TabBarButtonProps } from "@/components/tab-bar-button";
import { useMenuContext } from "@/contexts/menu-context";
import { useSidebarContext } from "@/contexts/sidebar-context";
import { tabBarMenuAnimationSpeed } from "@/lib/settings";
import { cva, cx } from "@/lib/utils";

export type TabBarMenuButtonProps = TabBarButtonProps & VariantProps<typeof variants>;

export type TabBarMenuButtonIconProps = HTMLAttributes<HTMLElement> & HTMLMotionProps<"div">;

const variants = cva({});

const duration = tabBarMenuAnimationSpeed;

const animationVariants: Variants = {
  show: { y: "0%", transition: { duration, ease: "circInOut" } },
  hide: { y: "-100%", transition: { duration, ease: "circInOut" } },
};

function TabBarMenuButtonIcon({ children, ...props }: TabBarMenuButtonIconProps) {
  const { isMenuOpen } = useMenuContext();

  return (
    <motion.div
      variants={animationVariants}
      initial={false}
      animate={isMenuOpen ? "show" : "hide"}
      className="flex-shrink-0"
      {...props}
    >
      {children}
    </motion.div>
  );
}

export function TabBarMenuButton({ className, ...props }: TabBarMenuButtonProps) {
  const { isMenuOpen, toggleMenu } = useMenuContext();
  const { openSidebar } = useSidebarContext();

  return (
    <TabBarButton
      onPress={() => {
        openSidebar();
        toggleMenu();
      }}
      aria-label={isMenuOpen ? "Close Menu" : "Open Menu"}
      icon={
        <div className="inline-flex size-5 flex-col items-center overflow-hidden">
          <TabBarMenuButtonIcon aria-hidden={!isMenuOpen}>
            <IconX strokeWidth={1.5} className="size-full" />
          </TabBarMenuButtonIcon>
          <TabBarMenuButtonIcon aria-hidden={isMenuOpen}>
            <IconMenu strokeWidth={1.5} className="size-full" />
          </TabBarMenuButtonIcon>
        </div>
      }
      className={cx(variants(), className)}
      {...props}
    />
  );
}
