"use client";

import { type VariantProps } from "cva";
import { type HTMLMotionProps, motion, type Variants } from "framer-motion";

import { Link, type LinkProps, type LinkPropsWithRequiredChildren } from "@/components/link";
import {
  LogoSvg,
  LogoSvgAcronym,
  type LogoSvgAcronymProps,
  LogoSvgName,
  type LogoSvgNameProps,
} from "@/components/svg/logo-svg";
import { useSidebarContext } from "@/contexts/sidebar-context";
import { sidebarMenuAnimationSpeed } from "@/lib/settings";
import { cva, cx, remToPx } from "@/lib/utils";

export type LogoProps = LinkProps & HTMLMotionProps<"a"> & VariantProps<typeof variants>;

type LogoAcronymProps = LogoSvgAcronymProps;

type LogoNameProps = LogoSvgNameProps;

const variants = cva({
  base: "w-fit min-w-5 max-w-[calc(100%-0.5rem)] origin-left rounded-md px-1",
});

const duration = sidebarMenuAnimationSpeed;

const logoAnimationVariants: Variants = {
  normal: { x: remToPx(1.75), transition: { duration, delay: duration, ease: "easeInOut" } },
  tight: { x: remToPx(0.25), transition: { duration, delay: duration, ease: "easeInOut" } },
};

const svgAnimationVariants: Variants = {
  normal: { scale: 1, transition: { duration, delay: duration, ease: "easeInOut" } },
  tight: { scale: 0.625, transition: { duration, delay: duration, ease: "easeInOut" } },
};

const acronymAnimationVariants: Variants = {
  show: { opacity: 1, transition: { duration, delay: duration * 2, ease: "easeInOut" } },
  hide: { opacity: 0, transition: { duration, ease: "easeInOut" } },
};

const nameAnimationVariants: Variants = {
  show: { opacity: 1, transition: { duration, delay: duration * 2, ease: "easeInOut" } },
  hide: { opacity: 0, transition: { duration, ease: "easeInOut" } },
};

const MotionLink = motion<LinkPropsWithRequiredChildren>(Link);

export function Logo({ className, ...props }: LogoProps) {
  const { isSidebarOpen } = useSidebarContext();

  return (
    <MotionLink
      variants={logoAnimationVariants}
      initial={false}
      animate={isSidebarOpen ? "normal" : "tight"}
      variant="block"
      className={cx(variants(), className)}
      {...props}
    >
      <LogoSvg
        variants={svgAnimationVariants}
        initial={false}
        animate={isSidebarOpen ? "normal" : "tight"}
        className="relative flex-shrink-0 origin-left"
      >
        <LogoAcronym />
        <LogoName />
      </LogoSvg>
    </MotionLink>
  );
}

function LogoAcronym({ ...props }: LogoAcronymProps) {
  const { isSidebarOpen } = useSidebarContext();

  return (
    <LogoSvgAcronym
      variants={acronymAnimationVariants}
      initial={false}
      animate={!isSidebarOpen ? "show" : "hide"}
      aria-hidden={isSidebarOpen}
      {...props}
    />
  );
}

function LogoName({ ...props }: LogoNameProps) {
  const { isSidebarOpen } = useSidebarContext();

  return (
    <LogoSvgName
      variants={nameAnimationVariants}
      initial={false}
      animate={isSidebarOpen ? "show" : "hide"}
      aria-hidden={!isSidebarOpen}
      {...props}
    />
  );
}
