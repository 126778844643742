"use client";

import { type VariantProps } from "cva";
import { type ForwardedRef, forwardRef } from "react";
import {
  Button as ReactAriaButton,
  type ButtonProps as ReactAriaButtonProps,
} from "react-aria-components";

import { Link, type LinkPropsWithoutVariants } from "@/components/link";
import { cva, cx } from "@/lib/utils";

export type ButtonCommonProps = VariantProps<typeof variants>;

export type ButtonProps = ReactAriaButtonProps & ButtonCommonProps;

export type ButtonLinkProps = LinkPropsWithoutVariants &
  ButtonCommonProps & {
    isExternal?: boolean;
  };

export const variants = cva({
  base: "font-button no-focus focus-ring group relative flex h-fit w-full max-w-fit items-center justify-center gap-x-2 rounded-md transition-colors *:flex-shrink-0",
  variants: {
    intent: {
      default: "px-4 py-2.5",
      menu: "border-none p-1",
    },
    variant: {
      solid: "",
      outline: "",
    },
    theme: {
      primary: "",
      secondary: "",
    },
  },
  compoundVariants: [
    {
      variant: "solid",
      theme: "primary",
      className:
        "border border-transparent bg-brand-red text-white active:!bg-brand-gray-900 hocus:bg-brand-red-600",
    },
    {
      variant: "outline",
      theme: "primary",
      className:
        "border border-current text-brand-red active:!text-brand-gray-900 hocus:text-brand-red-600",
    },
    {
      variant: "solid",
      theme: "secondary",
      className:
        "border border-transparent bg-brand-gray-900 text-white active:!bg-brand-red-600 hocus:bg-brand-red",
    },
    {
      variant: "outline",
      theme: "secondary",
      className:
        "border border-current text-brand-gray-900 active:!text-brand-red-600 hocus:text-brand-red",
    },
  ],
  defaultVariants: {
    intent: "default",
    variant: "solid",
    theme: "primary",
  },
});

export const Button = forwardRef(function Button(
  { intent, variant, theme, children, className, ...props }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <ReactAriaButton
      ref={ref}
      className={cx(variants({ intent, variant, theme, className }))}
      {...props}
    >
      {children}
    </ReactAriaButton>
  );
});

export const ButtonLink = forwardRef(function ButtonLink(
  { isExternal, intent, variant, theme, className, ...props }: ButtonLinkProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) {
  return (
    <Link
      ref={ref}
      intent={isExternal ? "external" : "default"}
      variant="block"
      className={cx(variants({ intent, variant, theme, className }))}
      {...props}
    />
  );
});
