import { tailwindConfig } from "@/lib/utils";

export const sidebarMaximumWidth = tailwindConfig.theme.spacing["sidebar-max-w"];

export const sidebarMinimumWidth = tailwindConfig.theme.spacing["sidebar-min-w"];

export const sidebarAnimationSpeed = 0.6;

export const sidebarMenuAnimationSpeed = sidebarAnimationSpeed / 2.5;

export const sidebarToggleAnimationSpeed = sidebarAnimationSpeed;

export const menuAnimationSpeed = 0.6;

export const tabBarMenuAnimationSpeed = sidebarAnimationSpeed;

export const maximumEventsOnHomepage = 3;
