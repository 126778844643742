import { type VariantProps } from "cva";
import { type HTMLAttributes } from "react";

import { Logo } from "@/components/logo";
import { cva, cx } from "@/lib/utils";
import { resolveSanityUrl } from "@/sanity/lib/utils";

export type HeaderProps = HTMLAttributes<HTMLElement> & VariantProps<typeof variants>;

const variants = cva({
  base: "flex flex-col gap-y-4 text-brand-gray-600 md:gap-y-8",
});

export function Header({ children, className, ...props }: HeaderProps) {
  return (
    <header className={cx(variants(), className)} {...props}>
      <Logo href={resolveSanityUrl({ type: "home" })} />
      {children}
    </header>
  );
}
