"use client";

import { createContext, type ReactNode, useContext, useState } from "react";

import { type SanityTheme } from "@/sanity/schemas/documents/collections/theme";
import { type SanityTopic } from "@/sanity/schemas/documents/collections/topic";

export type SidebarMenu = Record<string, Record<string, object>>;

export type SidebarSubLink = SanityTheme | SanityTopic;

type SidebarContextValues = {
  isSidebarOpen: boolean;
  isSidebarVisible: boolean;
  openSidebar: () => void;
  closeSidebar: () => void;
  toggleSidebar: () => void;
  sidebarExitComplete: () => void;
};

type SidebarProviderProps = {
  children: ReactNode;
};

const SidebarContext = createContext<SidebarContextValues | undefined>(undefined);

export function SidebarProvider({ children }: SidebarProviderProps) {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const [isSidebarVisible, setIsSidebarVisible] = useState<boolean>(false);

  const openSidebar = () => {
    setIsSidebarOpen(true);
    setIsSidebarVisible(true);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const toggleSidebar = () => {
    if (isSidebarOpen) closeSidebar();
    else openSidebar();
  };

  const sidebarExitComplete = () => {
    setIsSidebarVisible(false);
  };

  return (
    <SidebarContext.Provider
      value={{
        isSidebarOpen,
        isSidebarVisible,
        openSidebar,
        closeSidebar,
        toggleSidebar,
        sidebarExitComplete,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
}

export function useSidebarContext() {
  const sidebarContext = useContext(SidebarContext);

  if (!sidebarContext)
    throw new Error("useSidebarContext can only be used within <SidebarProvider>");

  return sidebarContext;
}
