"use client";

import { type VariantProps } from "cva";

import { Accordion, type AccordionProps } from "@/components/accordion";
import { cva, cx } from "@/lib/utils";

export type SidebarAccordionProps = AccordionProps & VariantProps<typeof variants>;

const variants = cva({
  base: "relative flex flex-col [&>[role=region]>*:first-child]:mt-2 [&>button]:pr-4 [&_[role=region]]:space-y-2",
});

export function SidebarAccordion({ children, className, ...props }: SidebarAccordionProps) {
  return (
    <Accordion
      spacing="none"
      animateContent={true}
      className={cx(variants(), className)}
      {...props}
    >
      {children}
    </Accordion>
  );
}
