"use client";

import { type VariantProps } from "cva";
import { type HTMLMotionProps, motion, type Variants } from "framer-motion";
import { type HTMLAttributes } from "react";

import { Image } from "@/components/image";
import { Link, type LinkProps, type LinkPropsWithRequiredChildren } from "@/components/link";
import { useSidebarContext } from "@/contexts/sidebar-context";
import { sidebarMenuAnimationSpeed } from "@/lib/settings";
import { cva, cx, remToPx } from "@/lib/utils";
import logomark from "@/public/logomark.png";
import logo from "@/public/logotype.png";

export type CpiLogoProps = LinkProps & HTMLMotionProps<"a"> & VariantProps<typeof variants>;

type LogomarkProps = HTMLAttributes<HTMLElement> & HTMLMotionProps<"div">;

type LogotypeProps = HTMLAttributes<HTMLElement> & HTMLMotionProps<"div">;

const variants = cva({
  base: "relative w-fit max-w-[calc(100%-1rem)] origin-left rounded-md px-1",
});

const duration = sidebarMenuAnimationSpeed;

const logoAnimationVariants: Variants = {
  normal: { x: remToPx(1.75), transition: { duration, delay: duration, ease: "easeInOut" } },
  tight: { x: remToPx(0.5), transition: { duration, delay: duration, ease: "easeInOut" } },
};

const pngAnimationVariants: Variants = {
  normal: { scale: 1, transition: { duration, delay: duration, ease: "easeInOut" } },
  tight: { scale: 0.563, transition: { duration, delay: duration, ease: "easeInOut" } },
};

const logotypeAnimationVariants: Variants = {
  show: { opacity: 1, transition: { duration, delay: duration * 2, ease: "easeInOut" } },
  hide: { opacity: 0, transition: { duration, ease: "easeInOut" } },
};

const MotionLink = motion<LinkPropsWithRequiredChildren>(Link);

export function CpiLogo({ className, ...props }: CpiLogoProps) {
  const { isSidebarOpen } = useSidebarContext();

  return (
    <MotionLink
      variants={logoAnimationVariants}
      initial={false}
      animate={isSidebarOpen ? "normal" : "tight"}
      variant="block"
      className={cx(variants(), className)}
      {...props}
    >
      <motion.div
        variants={pngAnimationVariants}
        initial={false}
        animate={isSidebarOpen ? "normal" : "tight"}
        className="relative h-14 flex-shrink-0 origin-left"
      >
        <Logomark />
        <Logotype />
      </motion.div>
    </MotionLink>
  );
}

function Logomark({ ...props }: LogomarkProps) {
  return (
    <motion.div className="absolute left-0 top-0 h-full" {...props}>
      <Image src={logomark} alt="Climate Policy Initiative emblem" fit="contain" />
    </motion.div>
  );
}

function Logotype({ ...props }: LogotypeProps) {
  const { isSidebarOpen } = useSidebarContext();

  return (
    <motion.div
      variants={logotypeAnimationVariants}
      initial={false}
      animate={isSidebarOpen ? "show" : "hide"}
      className="h-full"
      aria-hidden={!isSidebarOpen}
      {...props}
    >
      <Image src={logo} alt="Climate Policy Initiative logo" fit="contain" />
    </motion.div>
  );
}
