"use client";

import { useLiveMode } from "@sanity/react-loader";
import { VisualEditing } from "next-sanity";
import { useEffect } from "react";

import { isProduction } from "@/lib/utils";
import { disableDraftApiUrl } from "@/sanity/lib/api";
import { client } from "@/sanity/lib/client";

const stegaClient = client.withConfig({ stega: true });

export function LiveVisualEditing() {
  useLiveMode({ client: stegaClient });

  useEffect(() => {
    if (isProduction && window === parent) location.href = disableDraftApiUrl;
  }, []);

  return <VisualEditing />;
}

export default LiveVisualEditing;
