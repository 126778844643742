"use client";

import { useRouter } from "next/navigation";
import { type ReactNode, Suspense } from "react";
import { RouterProvider } from "react-aria-components";

import { FilterProvider } from "@/contexts/filter-context";
import { MenuProvider } from "@/contexts/menu-context";
import { SearchParamsProvider } from "@/contexts/search-params-context";
import { SidebarProvider } from "@/contexts/sidebar-context";

declare module "react-aria-components" {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface RouterConfig {
    routerOptions: NonNullable<Parameters<ReturnType<typeof useRouter>["push"]>[1]>;
  }
}

export type ProvidersInterface = {
  children: ReactNode;
};

export function Providers({ children }: ProvidersInterface) {
  const router = useRouter();

  return (
    <RouterProvider navigate={router.push}>
      <SidebarProvider>
        <MenuProvider>
          <Suspense>
            <SearchParamsProvider>
              <FilterProvider>{children}</FilterProvider>
            </SearchParamsProvider>
          </Suspense>
        </MenuProvider>
      </SidebarProvider>
    </RouterProvider>
  );
}
