"use client";

import { usePathname } from "next/navigation";

import { isDefinedAndNotEmpty } from "@/lib/utils";
import { resolveSanityLink } from "@/sanity/lib/utils";
import { type SanityLink } from "@/sanity/schemas/types/link";

type UseLinkProps = {
  link: SanityLink | undefined;
  href?: string;
};

export const createSegments = (string: string | undefined) => string?.split("/").filter(Boolean);

export function useLink({ link, href }: UseLinkProps) {
  const pathname = usePathname();

  let url, target, label;

  if (isDefinedAndNotEmpty(link)) {
    const resolvedLink = resolveSanityLink(link);

    url = resolvedLink.url;
    target = resolvedLink.target;
    label = resolvedLink.label;
  } else if (isDefinedAndNotEmpty(href)) {
    url = href;
  }

  const pathnameSegments = createSegments(pathname);
  const urlSegments = createSegments(url);

  const activePathnameSegment = pathnameSegments?.at(-1);
  const activeUrlSegment = urlSegments?.at(-1);

  const isActive = activePathnameSegment === activeUrlSegment;

  return {
    url,
    target,
    label,
    isActive,
    pathnameSegments,
    urlSegments,
    activePathnameSegment,
    activeUrlSegment,
  };
}
