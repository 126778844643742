"use client";

import { type VariantProps } from "cva";
import { AnimatePresence, type HTMLMotionProps, motion, type Variants } from "framer-motion";
import { type ReactNode } from "react";
import { Dialog, Modal, type ModalOverlayProps } from "react-aria-components";

import { Footer } from "@/components/footer";
import { Header } from "@/components/header";
import { useMenuContext } from "@/contexts/menu-context";
import { menuAnimationSpeed } from "@/lib/settings";
import { cva, cx } from "@/lib/utils";

export type MenuProps = ModalOverlayProps &
  HTMLMotionProps<"div"> &
  VariantProps<typeof variants> & {
    headerNavigation: ReactNode;
    footerNavigation: ReactNode;
  };

const variants = cva({
  base: "fixed inset-0 z-20 block h-screen w-screen flex-shrink-0 overflow-hidden bg-brand-gray-400 supports-[height:100dvh]:h-[100dvh] supports-[width:100dvw]:w-[100dvw] sm:hidden",
});

const duration = menuAnimationSpeed;

const sidebarAnimationVariants: Variants = {
  open: { x: "0%", transition: { duration, ease: "circInOut" } },
  closed: { x: "-100%", transition: { duration, ease: "circInOut" } },
};

const MotionModal = motion(Modal);

export function Menu({ headerNavigation, footerNavigation, className, ...props }: MenuProps) {
  const { isMenuOpen, toggleMenu, menuExitComplete } = useMenuContext();

  return (
    <AnimatePresence initial={false} onExitComplete={menuExitComplete}>
      {isMenuOpen && (
        <MotionModal
          isOpen={isMenuOpen}
          onOpenChange={toggleMenu}
          isDismissable={true}
          variants={sidebarAnimationVariants}
          initial="closed"
          animate="open"
          exit="closed"
          className={cx(variants(), className)}
          {...props}
        >
          <Dialog className="no-focus h-full overflow-y-scroll py-md pb-[calc(theme(spacing.tab-bar-h)+1rem)] md:py-lg">
            <div className="flex min-h-full flex-col justify-between gap-y-2">
              <Header>{headerNavigation}</Header>
              <Footer>{footerNavigation}</Footer>
            </div>
          </Dialog>
        </MotionModal>
      )}
    </AnimatePresence>
  );
}
