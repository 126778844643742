"use client";

import { type VariantProps } from "cva";
import { motion } from "framer-motion";
import { type ReactNode } from "react";

import {
  Button,
  ButtonLink,
  type ButtonLinkProps,
  type ButtonProps,
  type variants as buttonVariants,
} from "@/components/button";
import { useMenuContext } from "@/contexts/menu-context";
import { useSidebarContext } from "@/contexts/sidebar-context";
import { useLink } from "@/hooks/use-link";
import { cva, cx } from "@/lib/utils";

export type TabBarButtonCommonProps = VariantProps<typeof variants> &
  VariantProps<typeof buttonVariants> & {
    children?: ReactNode;
    level?: number;
    icon?: ReactNode;
  };

export type TabBarButtonOuterProps = {
  children?: ReactNode;
  level?: number;
  isActive?: boolean;
};

export type TabBarButtonInnerProps = {
  children?: ReactNode;
  icon: ReactNode;
};

export type TabBarButtonProps = ButtonProps & TabBarButtonCommonProps;

export type TabBarButtonLinkProps = ButtonLinkProps & TabBarButtonCommonProps;

const variants = cva({
  base: "max-h-full justify-start text-current",
});

function TabBarButtonOuter({ isActive, children }: TabBarButtonOuterProps) {
  return (
    <div className="relative h-full">
      {isActive && (
        <motion.div
          layoutId="activeTabBarLink"
          className="absolute bottom-0 h-[3px] w-full flex-shrink-0 bg-brand-red"
        />
      )}
      <motion.div className="py-2">{children}</motion.div>
    </div>
  );
}

function TabBarButtonInner({ icon, children }: TabBarButtonInnerProps) {
  return (
    <div className="flex flex-col items-center gap-1">
      {icon}
      <span className="text-xs">{children}</span>
    </div>
  );
}

export function TabBarButton({ icon, children, className, ...props }: TabBarButtonProps) {
  const { closeMenu } = useMenuContext();
  const { openSidebar } = useSidebarContext();

  return (
    <TabBarButtonOuter>
      <Button
        intent="menu"
        variant="outline"
        theme="secondary"
        onPress={() => {
          openSidebar();
          closeMenu();
        }}
        className={cx(variants(), className)}
        {...props}
      >
        <TabBarButtonInner icon={icon}>{children}</TabBarButtonInner>
      </Button>
    </TabBarButtonOuter>
  );
}

export function TabBarButtonLink({
  link,
  href,
  level,
  icon,
  children,
  className,
  ...props
}: TabBarButtonLinkProps) {
  const { closeMenu } = useMenuContext();
  const { openSidebar } = useSidebarContext();
  const { url, target, label, isActive, activeUrlSegment, pathnameSegments } = useLink({
    link,
    href,
  });

  return (
    <TabBarButtonOuter level={level} isActive={isActive}>
      <ButtonLink
        href={url}
        target={target}
        intent="menu"
        variant="outline"
        theme="secondary"
        onPress={() => {
          openSidebar();
          closeMenu();
        }}
        className={cx(
          variants(),
          ((activeUrlSegment && pathnameSegments?.includes(activeUrlSegment)) || isActive) &&
            "text-brand-red",
          className,
        )}
        {...props}
      >
        <TabBarButtonInner icon={icon}>{children || label}</TabBarButtonInner>
      </ButtonLink>
    </TabBarButtonOuter>
  );
}
