"use client";

import { IconArrowRight } from "@tabler/icons-react";
import { type VariantProps } from "cva";
import { type ForwardedRef, forwardRef, type ReactNode } from "react";
import { Link as ReactAriaLink, type LinkProps as ReactAriaLinkProps } from "react-aria-components";

import { useLink } from "@/hooks/use-link";
import { cva, cx } from "@/lib/utils";
import { type SanityLink } from "@/sanity/schemas/types/link";

export type LinkCommonProps = ReactAriaLinkProps &
  VariantProps<typeof variants> & {
    children?: ReactNode;
  };

export type LinkPropsWithSanityLink = LinkCommonProps & {
  link: SanityLink;
  href?: never;
};

export type LinkPropsWithHref = LinkCommonProps & {
  link?: never;
};

export type LinkProps = LinkPropsWithSanityLink | LinkPropsWithHref;

export type LinkPropsWithoutVariants =
  | Omit<LinkPropsWithSanityLink, keyof VariantProps<typeof variants>>
  | Omit<LinkPropsWithHref, keyof VariantProps<typeof variants>>;

export type LinkPropsWithRequiredChildren = LinkProps & { children: ReactNode };

export const variants = cva({
  base: "no-focus focus-ring group relative w-fit transition-colors",
  variants: {
    intent: {
      default: "",
      external: "justify-between gap-x-2",
    },
    variant: {
      default: "",
      inline: "inline-flex",
      block: "flex h-full w-full",
    },
    state: {
      true: "",
    },
    theme: {
      primary: "",
    },
  },
  compoundVariants: [
    {
      intent: ["default", "external"],
      variant: ["default", "inline"],
      theme: "primary",
      className: "text-brand-red active:!text-brand-gray-900 hocus:text-brand-red-600",
    },
  ],
  defaultVariants: {
    intent: "default",
    variant: "default",
    theme: "primary",
  },
});

export const Link = forwardRef(function Link(
  { link, intent, variant, state, children, className, ...props }: LinkProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) {
  const { url, target, label, isActive } = useLink({ link });

  return (
    <ReactAriaLink
      ref={ref}
      href={url}
      target={target}
      className={cx(
        variants({ intent, variant, state: state !== false ? state : isActive }),
        className,
      )}
      {...props}
    >
      <>
        {children || label}
        {intent === "external" && <IconArrowRight strokeWidth={1.5} className="flex-shrink-0" />}
      </>
    </ReactAriaLink>
  );
});
