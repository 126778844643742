"use client";

import { useEffect, useState } from "react";

import { disableDraftApiUrl } from "@/sanity/lib/api";

export function DraftBanner() {
  const [isPresentation, setIsPresentation] = useState<boolean>(true);

  useEffect(() => {
    setIsPresentation(window !== parent);
  }, []);

  return (
    !isPresentation && (
      <div className="sticky top-0 z-10 bg-black p-4 text-center text-sm text-white">
        <span>
          You&apos;re currently in Sanity&apos;s <strong>Draft Mode</strong>. Your unpublished
          content and changes will be visible.
        </span>{" "}
        <a className="underline" href={disableDraftApiUrl}>
          Go back to Published Mode
        </a>
        .
      </div>
    )
  );
}

export default DraftBanner;
