import { type VariantProps } from "cva";
import { motion, type Variants } from "framer-motion";
import { type HTMLAttributes } from "react";

import { CpiLogo } from "@/components/cpi-logo";
import { useSidebarContext } from "@/contexts/sidebar-context";
import { sidebarMenuAnimationSpeed } from "@/lib/settings";
import { cva, cx } from "@/lib/utils";

export type FooterProps = HTMLAttributes<HTMLElement> & VariantProps<typeof variants>;

const variants = cva({
  base: "space-y-4 text-brand-gray-600 md:space-y-8",
});

const duration = sidebarMenuAnimationSpeed;

const copyrightAnimationVariants: Variants = {
  show: {
    height: "auto",
    opacity: 1,
    transition: { duration, delay: duration * 2, ease: "easeInOut" },
  },
  hide: {
    height: "0",
    opacity: 0,
    transition: { duration, ease: "easeInOut" },
  },
};

export function Footer({ children, className, ...props }: FooterProps) {
  const { isSidebarOpen } = useSidebarContext();

  return (
    <footer className={cx(variants(), className)} {...props}>
      <div className="flex flex-col">{children}</div>
      <div>
        <CpiLogo href="https://www.climatepolicyinitiative.org" target="_blank" />
        <motion.div
          variants={copyrightAnimationVariants}
          initial={false}
          animate={isSidebarOpen ? "show" : "hide"}
          aria-hidden={!isSidebarOpen}
          className="overflow-hidden px-8 text-[12px] text-brand-gray-600"
        >
          <p className="mt-4 min-w-max">
            © 2024 Climate Policy Initiative
            <br />
            Empowered by Driftime®
          </p>
        </motion.div>
      </div>
    </footer>
  );
}
