import { type VariantProps } from "cva";
import { type HTMLAttributes } from "react";

import { AccordionGroup } from "@/components/accordion";
import { HeaderNavigationLink } from "@/components/header-navigation-link";
import { type SidebarSubLink } from "@/contexts/sidebar-context";
import { cva, cx, isDefinedAndNotEmpty } from "@/lib/utils";
import { getIcon, resolveSanityUrl } from "@/sanity/lib/utils";

export type HeaderNavigationSubmenuProps = HTMLAttributes<HTMLElement> &
  VariantProps<typeof variants> & {
    level: number;
    subLinks: SidebarSubLink[];
  };

export const variants = cva({
  base: "space-y-2",
});

export function HeaderNavigationSubmenu({
  level,
  subLinks,
  className,
  ...props
}: HeaderNavigationSubmenuProps) {
  return (
    <nav className={cx(variants(), className)} {...props}>
      <AccordionGroup>
        {subLinks.map((subLink, index) => {
          const isTheme = subLink._type === "theme";
          const isTopic = subLink._type === "topic";

          const { _type, title, slug } = subLink;
          const { icon: iconName, topics, topicsCount } = (isTheme && subLink) || {};
          const { theme } = (isTopic && subLink) || {};

          const Icon = getIcon(iconName)?.icon;

          const subLinks = isTheme ? topics : undefined;
          const subLinksCount = isTheme ? topicsCount : undefined;

          return (
            <HeaderNavigationLink
              key={index}
              level={level + 1}
              icon={isDefinedAndNotEmpty(Icon) && <Icon strokeWidth={1.5} className="size-5" />}
              href={resolveSanityUrl({
                type: _type,
                slug: slug?.current,
                themeSlug: theme?.slug?.current,
              })}
              subLinks={subLinks}
            >
              {isDefinedAndNotEmpty(subLinks) ? `${title} (${subLinksCount})` : title}
            </HeaderNavigationLink>
          );
        })}
      </AccordionGroup>
    </nav>
  );
}
