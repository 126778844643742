import { createClient } from "@sanity/client";

import { isDevelopment } from "@/lib/utils";
import { apiVersion, dataset, projectId, revalidateSecret, studioUrl } from "@/sanity/lib/api";

export const client = createClient({
  projectId,
  dataset,
  apiVersion,
  perspective: "published",
  useCdn: revalidateSecret && !isDevelopment ? false : true,
  stega: {
    studioUrl,
    // logger: console,
  },
});
