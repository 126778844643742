import { type VariantProps } from "cva";
import { motion, type SVGMotionProps } from "framer-motion";
import { type SVGAttributes } from "react";

import { cva, cx, isDefinedAndNotEmpty } from "@/lib/utils";

export type LogoSvgProps = SVGAttributes<SVGElement> &
  SVGMotionProps<"svg"> &
  VariantProps<typeof variants>;

export type LogoSvgAcronymProps = SVGAttributes<SVGPathElement> & SVGMotionProps<"g">;

export type LogoSvgNameProps = SVGAttributes<SVGPathElement> & SVGMotionProps<"g">;

const variants = cva({
  base: "w-48",
});

export function LogoSvg({ children, className, ...props }: LogoSvgProps) {
  return (
    <motion.svg
      width="100%"
      height="100%"
      viewBox="0 0 229 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cx(variants(), className)}
      role="img"
      aria-label="Climate Finance Reform Compass"
      {...props}
    >
      {isDefinedAndNotEmpty(children) ? (
        children
      ) : (
        <>
          <LogoSvgAcronym />
          <LogoSvgName />
        </>
      )}
    </motion.svg>
  );
}

export function LogoSvgAcronym({ ...props }: LogoSvgAcronymProps) {
  return (
    <motion.g className="fill-brand-red" {...props}>
      <path d="M16.953,21.516c-1.757,1.118 -3.992,1.884 -6.483,1.884c-6.418,0 -10.442,-4.534 -10.442,-11.368c0,-6.834 4.184,-11.432 10.41,-11.432c2.555,-0 4.535,0.607 6.163,1.597l-0.606,3.991c-1.437,-1.085 -3.225,-1.948 -5.365,-1.948c-3.896,0 -6.291,2.97 -6.291,7.76c0,4.854 2.555,7.728 6.451,7.728c2.043,-0 3.895,-0.735 5.588,-1.852l0.575,3.64Zm14.944,-16.861l-8.111,0l0,5.589l6.195,-0l0.607,3.704l-6.802,-0l0,9.069l-4.215,-0l0,-22.034l12.326,0l0,3.672Zm12.652,3.162c0,-2.204 -1.405,-3.449 -4.055,-3.449l-2.331,0l-0,6.993l2.139,0c2.619,0 4.247,-1.245 4.247,-3.544Zm-0.255,5.971l5.875,8.654l-4.31,0.798l-5.748,-8.653l-1.916,-0l-0,8.43l-4.215,-0l-0,-22.034l6.833,0c4.918,0 7.888,2.331 7.888,6.61c-0,3.194 -1.693,5.205 -4.407,6.195Zm23.119,7.728c-1.757,1.118 -3.992,1.884 -6.483,1.884c-6.418,-0 -10.442,-4.534 -10.442,-11.368c0,-6.834 4.184,-11.432 10.41,-11.432c2.555,-0 4.535,0.607 6.164,1.597l-0.607,3.991c-1.437,-1.085 -3.225,-1.948 -5.365,-1.948c-3.896,0 -6.291,2.97 -6.291,7.76c0,4.854 2.555,7.728 6.451,7.728c2.043,-0 3.896,-0.735 5.588,-1.852l0.575,3.64Z" />
    </motion.g>
  );
}

export function LogoSvgName({ ...props }: LogoSvgNameProps) {
  return (
    <motion.g className="fill-brand-red" {...props}>
      <path d="M17.4317 21.6C15.6717 22.72 13.4317 23.488 10.9357 23.488C4.50368 23.488 0.47168 18.944 0.47168 12.096C0.47168 5.248 4.66368 0.640001 10.9037 0.640001C13.4637 0.640001 15.4477 1.248 17.0797 2.24L16.4717 6.24C15.0317 5.152 13.2397 4.288 11.0957 4.288C7.19168 4.288 4.79168 7.264 4.79168 12.064C4.79168 16.928 7.35168 19.808 11.2557 19.808C13.3037 19.808 15.1597 19.072 16.8557 17.952L17.4317 21.6Z" />
      <path d="M24.6704 23.104H20.8304V0.608002L24.6704 0V23.104Z" />
      <path d="M33.1169 2.88C33.1169 4.16 32.0929 5.056 30.8449 5.056C29.5649 5.056 28.5409 4.16 28.5409 2.88C28.5409 1.568 29.5649 0.640001 30.8449 0.640001C32.0929 0.640001 33.1169 1.568 33.1169 2.88ZM32.7329 23.104H28.8929V7.648H32.7329V23.104Z" />
      <path d="M58.9714 23.104H55.1314V12.992C55.1314 11.648 54.6514 10.72 53.2434 10.72C52.0594 10.72 50.9394 11.488 49.8194 12.64V23.104H45.9794V12.992C45.9794 11.648 45.4674 10.72 44.0594 10.72C42.8754 10.72 41.7554 11.488 40.6354 12.64V23.104H36.7954V7.648H40.0594L40.4114 9.824C41.8514 8.448 43.4194 7.328 45.4994 7.328C47.7074 7.328 48.8914 8.32 49.4674 9.824C50.8434 8.448 52.5394 7.328 54.6194 7.328C57.5314 7.328 58.9714 9.088 58.9714 11.968V23.104Z" />
      <path d="M75.1147 23.104H71.8187L71.4987 21.088C70.2507 22.4 68.6507 23.424 66.3467 23.424C63.7227 23.424 61.9627 21.824 61.9627 19.136C61.9627 15.392 65.0347 13.76 71.2747 13.152V12.704C71.2747 11.04 70.3147 10.4 68.6187 10.4C66.8267 10.4 65.1307 10.912 63.4667 11.648L62.9867 8.576C64.7787 7.872 66.6667 7.328 69.1307 7.328C73.1307 7.328 75.1147 8.8 75.1147 12.512V23.104ZM71.2747 18.848V15.392C66.8267 15.936 65.7707 17.248 65.7707 18.72C65.7707 19.84 66.4747 20.512 67.6587 20.512C68.9707 20.512 70.2187 19.904 71.2747 18.848Z" />
      <path d="M87.2404 23.104C86.6324 23.232 85.9284 23.36 84.8084 23.36C81.7684 23.36 79.7204 22.24 79.7204 18.72V10.784H77.5444V7.648H79.7204V2.752L83.4964 2.176V7.648H86.6644L87.1444 10.784H83.4964V18.08C83.4964 19.328 84.0724 20 85.5444 20C85.9924 20 86.4084 19.968 86.7604 19.872L87.2404 23.104Z" />
      <path d="M102.943 15.2C102.943 15.52 102.911 15.904 102.911 16.128H92.9909C93.1829 19.136 94.7829 20.448 97.1509 20.448C99.1349 20.448 100.767 19.712 102.463 18.528L102.911 21.536C101.247 22.688 99.2309 23.424 96.7349 23.424C92.4149 23.424 89.1829 20.96 89.1829 15.392C89.1829 10.368 92.1909 7.328 96.2229 7.328C100.863 7.328 102.943 10.816 102.943 15.2ZM99.1989 13.728C98.9429 11.328 97.9829 9.984 96.1269 9.984C94.5909 9.984 93.3429 11.2 93.0549 13.728H99.1989Z" />
      <path d="M125.625 4.704H117.497V10.304H123.705L124.313 14.016H117.497V23.104H113.273V1.024H125.625V4.704Z" />
      <path d="M132.429 2.88C132.429 4.16 131.405 5.056 130.157 5.056C128.877 5.056 127.853 4.16 127.853 2.88C127.853 1.568 128.877 0.640001 130.157 0.640001C131.405 0.640001 132.429 1.568 132.429 2.88ZM132.045 23.104H128.205V7.648H132.045V23.104Z" />
      <path d="M149.292 23.104H145.452V12.992C145.452 11.648 144.876 10.72 143.468 10.72C142.252 10.72 141.068 11.488 139.948 12.672V23.104H136.108V7.648H139.372L139.724 9.824C141.164 8.448 142.796 7.328 144.876 7.328C147.756 7.328 149.292 9.088 149.292 11.968V23.104Z" />
      <path d="M165.427 23.104H162.131L161.811 21.088C160.563 22.4 158.963 23.424 156.659 23.424C154.035 23.424 152.275 21.824 152.275 19.136C152.275 15.392 155.347 13.76 161.587 13.152V12.704C161.587 11.04 160.627 10.4 158.931 10.4C157.139 10.4 155.443 10.912 153.779 11.648L153.299 8.576C155.091 7.872 156.979 7.328 159.443 7.328C163.443 7.328 165.427 8.8 165.427 12.512V23.104ZM161.587 18.848V15.392C157.139 15.936 156.083 17.248 156.083 18.72C156.083 19.84 156.787 20.512 157.971 20.512C159.283 20.512 160.531 19.904 161.587 18.848Z" />
      <path d="M182.417 23.104H178.577V12.992C178.577 11.648 178.001 10.72 176.593 10.72C175.377 10.72 174.193 11.488 173.073 12.672V23.104H169.233V7.648H172.497L172.849 9.824C174.289 8.448 175.921 7.328 178.001 7.328C180.881 7.328 182.417 9.088 182.417 11.968V23.104Z" />
      <path d="M197.912 21.888C196.344 22.976 194.744 23.392 192.792 23.392C188.504 23.392 185.464 20.512 185.464 15.456C185.464 10.08 188.824 7.328 192.92 7.328C195 7.328 196.536 7.872 197.752 8.64L197.208 12.096C195.896 11.104 194.648 10.656 193.144 10.656C190.936 10.656 189.336 12.288 189.336 15.36C189.336 18.56 191.064 20.096 193.4 20.096C194.744 20.096 195.96 19.744 197.4 18.624L197.912 21.888Z" />
      <path d="M213.662 15.2C213.662 15.52 213.63 15.904 213.63 16.128H203.71C203.902 19.136 205.502 20.448 207.87 20.448C209.854 20.448 211.486 19.712 213.182 18.528L213.63 21.536C211.966 22.688 209.95 23.424 207.454 23.424C203.134 23.424 199.902 20.96 199.902 15.392C199.902 10.368 202.91 7.328 206.942 7.328C211.582 7.328 213.662 10.816 213.662 15.2ZM209.918 13.728C209.662 11.328 208.702 9.984 206.846 9.984C205.31 9.984 204.062 11.2 203.774 13.728H209.918Z" />
      <path d="M11.9277 40.872C11.9277 38.664 10.5197 37.416 7.86368 37.416H5.52768V44.424H7.67168C10.2957 44.424 11.9277 43.176 11.9277 40.872ZM11.6717 46.856L17.5597 55.528L13.2397 56.328L7.47968 47.656H5.55968V56.104H1.33568V34.024H8.18368C13.1117 34.024 16.0877 36.36 16.0877 40.648C16.0877 43.848 14.3917 45.864 11.6717 46.856Z" />
      <path d="M32.3179 48.2C32.3179 48.52 32.2859 48.904 32.2859 49.128H22.3659C22.5579 52.136 24.1579 53.448 26.5259 53.448C28.5099 53.448 30.1419 52.712 31.8379 51.528L32.2859 54.536C30.6219 55.688 28.6059 56.424 26.1099 56.424C21.7899 56.424 18.5579 53.96 18.5579 48.392C18.5579 43.368 21.5659 40.328 25.5979 40.328C30.2379 40.328 32.3179 43.816 32.3179 48.2ZM28.5739 46.728C28.3179 44.328 27.3579 42.984 25.5019 42.984C23.9659 42.984 22.7179 44.2 22.4299 46.728H28.5739Z" />
      <path d="M43.7404 36.552C43.3564 36.488 42.7804 36.424 42.1404 36.424C40.8284 36.424 40.1244 37 40.1244 38.376V40.648H43.0684L43.5484 43.784H40.1244V56.104H36.3164V43.784H34.0764V40.648H36.3164V37.992C36.3164 34.568 38.4924 33.288 41.6604 33.288C42.3324 33.288 42.8444 33.352 43.2284 33.416L43.7404 36.552Z" />
      <path d="M59.3992 48.392C59.3992 53.416 56.2952 56.424 52.0712 56.424C47.8792 56.424 44.8392 53.416 44.8392 48.392C44.8392 43.368 47.9112 40.328 52.1032 40.328C56.3272 40.328 59.3992 43.368 59.3992 48.392ZM55.6552 48.392C55.6552 45.544 54.3752 43.496 52.0712 43.496C49.7992 43.496 48.5832 45.512 48.5832 48.392C48.5832 51.208 49.8312 53.288 52.0712 53.288C54.4392 53.288 55.6552 51.272 55.6552 48.392Z" />
      <path d="M71.8262 44.136C69.4262 44.296 67.4422 45.576 66.3542 47.24V56.104H62.5142V40.648H65.7782L66.2582 43.752C67.2822 41.96 68.8182 40.456 71.2182 40.328L71.8262 44.136Z" />
      <path d="M96.4402 56.104H92.6002V45.992C92.6002 44.648 92.1202 43.72 90.7122 43.72C89.5282 43.72 88.4082 44.488 87.2882 45.64V56.104H83.4482V45.992C83.4482 44.648 82.9362 43.72 81.5282 43.72C80.3442 43.72 79.2242 44.488 78.1042 45.64V56.104H74.2642V40.648H77.5282L77.8802 42.824C79.3202 41.448 80.8882 40.328 82.9682 40.328C85.1762 40.328 86.3602 41.32 86.9362 42.824C88.3122 41.448 90.0082 40.328 92.0882 40.328C95.0002 40.328 96.4402 42.088 96.4402 44.968V56.104Z" />
      <path d="M123.619 54.6C121.859 55.72 119.619 56.488 117.123 56.488C110.691 56.488 106.659 51.944 106.659 45.096C106.659 38.248 110.851 33.64 117.091 33.64C119.651 33.64 121.635 34.248 123.267 35.24L122.659 39.24C121.219 38.152 119.427 37.288 117.283 37.288C113.379 37.288 110.979 40.264 110.979 45.064C110.979 49.928 113.539 52.808 117.443 52.808C119.491 52.808 121.347 52.072 123.043 50.952L123.619 54.6Z" />
      <path d="M140.337 48.392C140.337 53.416 137.233 56.424 133.009 56.424C128.817 56.424 125.777 53.416 125.777 48.392C125.777 43.368 128.849 40.328 133.041 40.328C137.265 40.328 140.337 43.368 140.337 48.392ZM136.593 48.392C136.593 45.544 135.313 43.496 133.009 43.496C130.737 43.496 129.521 45.512 129.521 48.392C129.521 51.208 130.769 53.288 133.009 53.288C135.377 53.288 136.593 51.272 136.593 48.392Z" />
      <path d="M165.628 56.104H161.788V45.992C161.788 44.648 161.308 43.72 159.9 43.72C158.716 43.72 157.596 44.488 156.476 45.64V56.104H152.636V45.992C152.636 44.648 152.124 43.72 150.716 43.72C149.532 43.72 148.412 44.488 147.292 45.64V56.104H143.452V40.648H146.716L147.068 42.824C148.508 41.448 150.076 40.328 152.156 40.328C154.364 40.328 155.548 41.32 156.124 42.824C157.5 41.448 159.196 40.328 161.276 40.328C164.188 40.328 165.628 42.088 165.628 44.968V56.104Z" />
      <path d="M183.659 47.944C183.659 53.704 180.459 56.264 176.907 56.264C175.435 56.264 174.251 56.008 173.323 55.656V61.48L169.483 62.056V40.648H172.747L173.099 42.76C174.187 41.512 175.691 40.328 177.835 40.328C181.067 40.328 183.659 42.76 183.659 47.944ZM179.787 48.168C179.787 45.192 178.539 43.72 176.811 43.72C175.435 43.72 174.347 44.584 173.323 45.896V52.52C174.251 52.968 174.987 53.192 176.299 53.192C178.283 53.192 179.787 51.56 179.787 48.168Z" />
      <path d="M199.083 56.104H195.787L195.467 54.088C194.219 55.4 192.619 56.424 190.315 56.424C187.691 56.424 185.931 54.824 185.931 52.136C185.931 48.392 189.003 46.76 195.243 46.152V45.704C195.243 44.04 194.283 43.4 192.587 43.4C190.795 43.4 189.099 43.912 187.435 44.648L186.955 41.576C188.747 40.872 190.635 40.328 193.099 40.328C197.099 40.328 199.083 41.8 199.083 45.512V56.104ZM195.243 51.848V48.392C190.795 48.936 189.739 50.248 189.739 51.72C189.739 52.84 190.443 53.512 191.627 53.512C192.939 53.512 194.187 52.904 195.243 51.848Z" />
      <path d="M214.345 51.56C214.345 54.6 212.169 56.392 208.105 56.392C205.865 56.392 203.561 55.688 202.089 54.888L202.601 51.688C204.265 52.776 206.313 53.512 208.233 53.512C209.897 53.512 210.793 52.904 210.793 51.912C210.793 50.824 210.121 50.376 207.657 49.768C203.913 48.808 202.345 47.72 202.345 44.904C202.345 42.248 204.457 40.36 208.041 40.36C210.057 40.36 211.913 40.744 213.449 41.448L212.937 44.648C211.401 43.784 209.577 43.24 208.041 43.24C206.601 43.24 205.897 43.784 205.897 44.648C205.897 45.48 206.473 45.928 208.777 46.536C212.777 47.56 214.345 48.648 214.345 51.56Z" />
      <path d="M228.72 51.56C228.72 54.6 226.544 56.392 222.48 56.392C220.24 56.392 217.936 55.688 216.464 54.888L216.976 51.688C218.64 52.776 220.688 53.512 222.608 53.512C224.272 53.512 225.168 52.904 225.168 51.912C225.168 50.824 224.496 50.376 222.032 49.768C218.288 48.808 216.72 47.72 216.72 44.904C216.72 42.248 218.832 40.36 222.416 40.36C224.432 40.36 226.288 40.744 227.824 41.448L227.312 44.648C225.776 43.784 223.952 43.24 222.416 43.24C220.976 43.24 220.272 43.784 220.272 44.648C220.272 45.48 220.848 45.928 223.152 46.536C227.152 47.56 228.72 48.648 228.72 51.56Z" />
      <path d="M5.85483 79.7526C5.85483 81.359 4.73734 82.104 2.88651 82.104H0.965837V74.0721H2.90979C4.67914 74.0721 5.50561 74.9218 5.50561 76.1557C5.50561 76.9706 5.07492 77.5177 4.35321 77.8203C5.24952 78.088 5.85483 78.6934 5.85483 79.7526ZM4.43469 76.1907C4.43469 75.4573 3.98071 75.0033 2.88651 75.0033H2.03676V77.4478H2.80503C3.86431 77.4478 4.43469 76.9822 4.43469 76.1907ZM4.7839 79.7759C4.7839 78.8447 4.13204 78.3441 2.93307 78.3441H2.03676V81.1728H2.96799C4.1204 81.1728 4.7839 80.7071 4.7839 79.7759Z" />
      <path d="M12.9389 74.0721L10.0172 78.9145V82.104H8.92299V78.9145L6.08272 74.2234L7.17693 73.9906L9.48174 77.9134L11.7283 74.0721H12.9389Z" />
      <path d="M22.0705 81.4754C21.4536 81.9294 20.5573 82.2553 19.6377 82.2553C17.3794 82.2553 15.9244 80.5791 15.9244 78.0997C15.9244 75.6086 17.4027 73.9208 19.6609 73.9208C20.6155 73.9208 21.3255 74.1652 21.9425 74.5843L21.7795 75.7018C21.1975 75.2594 20.5107 74.9218 19.6493 74.9218C18.0196 74.9218 17.0535 76.1557 17.0535 78.088C17.0535 80.0204 18.0313 81.2426 19.6493 81.2426C20.5107 81.2426 21.2673 80.905 21.9076 80.4394L22.0705 81.4754Z" />
      <path d="M27.6869 82.104H23.5429V74.0721H24.6371V81.1029H27.5355L27.6869 82.104Z" />
      <path d="M29.9192 82.104H28.825V74.0721H29.9192V82.104Z" />
      <path d="M40.4475 82.104H39.3999L38.8993 75.7134L36.3617 82.1389H35.5352L32.9976 75.7134L32.5087 82.104H31.4844L32.1246 74.0721H33.4749L35.9776 80.6606L38.4803 74.0721H39.8189L40.4475 82.104Z" />
      <path d="M48.6469 81.9527L47.5877 82.1855L46.6448 79.6944H43.3273L42.4193 82.104H41.3135L44.4564 74.0372H45.5506L48.6469 81.9527ZM46.2723 78.7283L44.9802 75.3409L43.6997 78.7283H46.2723Z" />
      <path d="M54.3814 75.0732H51.7507V82.104H50.6565V75.0732H48.0956V74.0721H54.2184L54.3814 75.0732Z" />
      <path d="M59.8712 82.104H55.4245V74.0721H59.7547V75.0732H56.5187V77.4013H59.033L59.196 78.4023H56.5187V81.1029H59.8712V82.104Z" />
      <path d="M68.2136 76.3885C68.2136 78.0298 67.0496 78.8447 65.3035 78.8447H64.4538V82.104H63.3596V74.0721H65.4898C67.1893 74.0721 68.2136 74.8986 68.2136 76.3885ZM67.1078 76.4235C67.1078 75.5039 66.5258 75.0382 65.4316 75.0382H64.4538V77.8785H65.3617C66.4559 77.8785 67.1078 77.4245 67.1078 76.4235Z" />
      <path d="M76.445 78.0764C76.445 80.5558 75.0015 82.2553 72.7317 82.2553C70.4734 82.2553 69.0416 80.5791 69.0416 78.0997C69.0416 75.6086 70.4967 73.9208 72.7549 73.9208C75.0248 73.9208 76.445 75.597 76.445 78.0764ZM75.3158 78.088C75.3158 76.1557 74.3264 74.9218 72.7433 74.9218C71.1486 74.9218 70.1708 76.1557 70.1708 78.088C70.1708 80.0204 71.1602 81.2542 72.7433 81.2542C74.338 81.2542 75.3158 80.0204 75.3158 78.088Z" />
      <path d="M81.9636 82.104H77.8196V74.0721H78.9138V81.1029H81.8123L81.9636 82.104Z" />
      <path d="M84.196 82.104H83.1018V74.0721H84.196V82.104Z" />
      <path d="M91.7909 81.4754C91.174 81.9294 90.2776 82.2553 89.358 82.2553C87.0998 82.2553 85.6447 80.5791 85.6447 78.0997C85.6447 75.6086 87.1231 73.9208 89.3813 73.9208C90.3358 73.9208 91.0459 74.1652 91.6629 74.5843L91.4999 75.7018C90.9179 75.2594 90.2311 74.9218 89.3697 74.9218C87.74 74.9218 86.7739 76.1557 86.7739 78.088C86.7739 80.0204 87.7517 81.2426 89.3697 81.2426C90.2311 81.2426 90.9877 80.905 91.6279 80.4394L91.7909 81.4754Z" />
      <path d="M99.2397 74.0721L96.3179 78.9145V82.104H95.2237V78.9145L92.3835 74.2234L93.4777 73.9906L95.7825 77.9134L98.0291 74.0721H99.2397Z" />
      <path d="M103.715 82.104H102.621V74.0721H103.715V82.104Z" />
      <path d="M111.741 82.104H110.914L106.503 75.8531V82.104H105.49V74.0721H106.468L110.728 80.0902V74.0721H111.741V82.104Z" />
      <path d="M114.606 82.104H113.512V74.0721H114.606V82.104Z" />
      <path d="M122.084 75.0732H119.454V82.104H118.359V75.0732H115.798V74.0721H121.921L122.084 75.0732Z" />
      <path d="M124.291 82.104H123.197V74.0721H124.291V82.104Z" />
      <path d="M132.771 81.9527L131.712 82.1855L130.769 79.6944H127.451L126.543 82.104H125.438L128.58 74.0372H129.675L132.771 81.9527ZM130.396 78.7283L129.104 75.3409L127.824 78.7283H130.396Z" />
      <path d="M138.505 75.0732H135.875V82.104H134.781V75.0732H132.22V74.0721H138.343L138.505 75.0732Z" />
      <path d="M140.713 82.104H139.618V74.0721H140.713V82.104Z" />
      <path d="M149.064 74.0721L145.933 82.1389H144.955L141.859 74.2234L142.953 73.9906L145.456 80.6722L147.912 74.0721H149.064Z" />
      <path d="M154.579 82.104H150.132V74.0721H154.463V75.0732H151.226V77.4013H153.741L153.904 78.4023H151.226V81.1029H154.579V82.104Z" />
    </motion.g>
  );
}
