"use client";

import { type VariantProps } from "cva";
import { motion, type Variants } from "framer-motion";
import { type ReactNode } from "react";

import {
  Button,
  ButtonLink,
  type ButtonLinkProps,
  type ButtonProps,
  type variants as buttonVariants,
} from "@/components/button";
import { useMenuContext } from "@/contexts/menu-context";
import { useSidebarContext } from "@/contexts/sidebar-context";
import { useLink } from "@/hooks/use-link";
import { sidebarMenuAnimationSpeed } from "@/lib/settings";
import { cva, cx, remToPx } from "@/lib/utils";

export type SidebarButtonCommonProps = VariantProps<typeof variants> &
  VariantProps<typeof buttonVariants> & {
    children?: ReactNode;
    level?: number;
    icon?: ReactNode;
  };

export type SidebarButtonOuterProps = {
  children?: ReactNode;
  level?: number;
  isActive?: boolean;
};

export type SidebarButtonInnerProps = {
  children?: ReactNode;
  icon: ReactNode;
};

export type SidebarButtonProps = ButtonProps & SidebarButtonCommonProps;

export type SidebarButtonLinkProps = ButtonLinkProps & SidebarButtonCommonProps;

const variants = cva({
  base: "max-h-full justify-start text-current",
});

const duration = sidebarMenuAnimationSpeed;

const buttonAnimationVariants = (level = 0): Variants => ({
  normal: {
    x: remToPx(1 + level),
    transition: { duration, delay: duration, ease: "easeInOut" },
  },
  tight: { x: 0, transition: { duration, delay: duration, ease: "easeInOut" } },
});

const textAnimationVariants: Variants = {
  show: { opacity: 1, transition: { duration, delay: duration, ease: "easeInOut" } },
  hide: { opacity: 0, transition: { duration, ease: "easeInOut" } },
};

function SidebarButtonOuter({ level, isActive, children }: SidebarButtonOuterProps) {
  const { isSidebarOpen } = useSidebarContext();

  return (
    <div className="relative flex max-w-[calc(100%+1rem)]">
      {isActive && (
        <motion.div
          layoutId="activeSidebarLink"
          className="absolute top-0 h-full w-[3px] flex-shrink-0 bg-brand-red"
        />
      )}
      <motion.div
        variants={buttonAnimationVariants(level)}
        initial="normal"
        animate={isSidebarOpen ? "normal" : "tight"}
        className="min-w-0 px-3 py-1"
      >
        {children}
      </motion.div>
    </div>
  );
}

function SidebarButtonInner({ icon, children }: SidebarButtonInnerProps) {
  const { isSidebarOpen } = useSidebarContext();

  return (
    <>
      {icon}
      <motion.span
        variants={textAnimationVariants}
        initial="show"
        animate={isSidebarOpen ? "show" : "hide"}
        className="pt-0.5 text-left leading-tight"
      >
        <span className="inline-block max-w-[calc(theme(spacing.sidebar-max-w)-8rem)]">
          {children}
        </span>
      </motion.span>
    </>
  );
}

export function SidebarButton({ icon, children, className, ...props }: SidebarButtonProps) {
  const { closeMenu } = useMenuContext();

  return (
    <SidebarButtonOuter>
      <Button
        intent="menu"
        variant="outline"
        theme="secondary"
        onPress={closeMenu}
        className={cx(variants(), className)}
        {...props}
      >
        <SidebarButtonInner icon={icon}>{children}</SidebarButtonInner>
      </Button>
    </SidebarButtonOuter>
  );
}

export function SidebarButtonLink({
  link,
  href,
  level,
  icon,
  children,
  className,
  ...props
}: SidebarButtonLinkProps) {
  const { closeMenu } = useMenuContext();
  const { url, target, label, isActive, activeUrlSegment, pathnameSegments } = useLink({
    link,
    href,
  });

  return (
    <SidebarButtonOuter level={level} isActive={isActive}>
      <ButtonLink
        href={url}
        target={target}
        intent="menu"
        variant="outline"
        theme="secondary"
        onPress={closeMenu}
        className={cx(
          variants(),
          ((activeUrlSegment && pathnameSegments?.includes(activeUrlSegment)) || isActive) &&
            "text-brand-red",
          className,
        )}
        {...props}
      >
        <SidebarButtonInner icon={icon}>{children || label}</SidebarButtonInner>
      </ButtonLink>
    </SidebarButtonOuter>
  );
}
