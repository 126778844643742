import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/app/(frontend)/providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/draft-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderNavigationLink"] */ "/vercel/path0/components/header-navigation-link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Menu"] */ "/vercel/path0/components/menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarButtonLink"] */ "/vercel/path0/components/sidebar-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarToggleButton"] */ "/vercel/path0/components/sidebar-toggle-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Sidebar"] */ "/vercel/path0/components/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TabBarButtonLink"] */ "/vercel/path0/components/tab-bar-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TabBarMenuButton"] */ "/vercel/path0/components/tab-bar-menu-button.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/default-seo-image.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/sanity/loader/live-visual-editing.tsx");
