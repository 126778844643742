"use client";

import { HeaderNavigationSubmenu } from "@/components/header-navigation-submenu";
import { SidebarAccordion } from "@/components/sidebar-accordion";
import { SidebarButtonLink, type SidebarButtonLinkProps } from "@/components/sidebar-button";
import { type SidebarSubLink, useSidebarContext } from "@/contexts/sidebar-context";
import { useLink } from "@/hooks/use-link";
import { cva, cx, isDefinedAndNotEmpty } from "@/lib/utils";
import { getIcon } from "@/sanity/lib/utils";

export type HeaderNavigationLinkProps = SidebarButtonLinkProps & {
  level?: number;
  subLinks?: SidebarSubLink[] | undefined;
};

export const variants = cva({});

export function HeaderNavigationLink({
  level = 0,
  subLinks,
  className,
  ...props
}: HeaderNavigationLinkProps) {
  const { link, href } = props;

  const { isSidebarOpen } = useSidebarContext();
  const { activeUrlSegment, pathnameSegments } = useLink({ link, href });

  const isAccordionActive =
    (activeUrlSegment && pathnameSegments?.includes(activeUrlSegment)) || false;

  const { icon: iconName } = link || {};
  const Icon = getIcon(iconName)?.icon;

  return (
    <SidebarAccordion
      header={
        <SidebarButtonLink
          level={level}
          icon={isDefinedAndNotEmpty(Icon) && <Icon strokeWidth={1.5} className="size-5" />}
          className={cx(variants(), className)}
          {...props}
        />
      }
      isOpen={isSidebarOpen === false ? false : isAccordionActive}
      defaultOpen={isAccordionActive}
    >
      {isDefinedAndNotEmpty(subLinks) && (
        <HeaderNavigationSubmenu level={level} subLinks={subLinks} />
      )}
    </SidebarAccordion>
  );
}
