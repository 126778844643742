"use client";

import { IconLayoutSidebarLeftCollapse, IconMenu } from "@tabler/icons-react";
import { type VariantProps } from "cva";
import { type HTMLMotionProps, motion, type Variants } from "framer-motion";
import { type HTMLAttributes } from "react";

import { SidebarButton, type SidebarButtonProps } from "@/components/sidebar-button";
import { useSidebarContext } from "@/contexts/sidebar-context";
import { sidebarToggleAnimationSpeed } from "@/lib/settings";
import { cva, cx } from "@/lib/utils";

export type SidebarToggleButtonProps = SidebarButtonProps & VariantProps<typeof variants>;

export type SidebarToggleButtonIconProps = HTMLAttributes<HTMLElement> & HTMLMotionProps<"div">;

const variants = cva({
  base: "hidden sm:flex",
});

const duration = sidebarToggleAnimationSpeed;

const animationVariants: Variants = {
  show: { y: "0%", transition: { duration, ease: "circInOut" } },
  hide: { y: "-100%", transition: { duration, ease: "circInOut" } },
};

function SidebarToggleButtonIcon({ children, ...props }: SidebarToggleButtonIconProps) {
  const { isSidebarOpen } = useSidebarContext();

  return (
    <motion.div
      variants={animationVariants}
      initial={false}
      animate={isSidebarOpen ? "show" : "hide"}
      className="flex-shrink-0"
      {...props}
    >
      {children}
    </motion.div>
  );
}

export function SidebarToggleButton({ className, ...props }: SidebarToggleButtonProps) {
  const { isSidebarOpen, toggleSidebar } = useSidebarContext();

  return (
    <SidebarButton
      onPress={toggleSidebar}
      aria-label={isSidebarOpen ? "Hide Sidebar" : "Open Sidebar"}
      icon={
        <div className="inline-flex size-5 flex-col items-center overflow-hidden">
          <SidebarToggleButtonIcon aria-hidden={!isSidebarOpen}>
            <IconLayoutSidebarLeftCollapse strokeWidth={1.5} className="size-full" />
          </SidebarToggleButtonIcon>
          <SidebarToggleButtonIcon aria-hidden={isSidebarOpen}>
            <IconMenu strokeWidth={1.5} className="size-full" />
          </SidebarToggleButtonIcon>
        </div>
      }
      className={cx(variants(), className)}
      {...props}
    />
  );
}
