"use client";

import { createContext, type ReactNode, useContext, useState } from "react";

import { type SanityTheme } from "@/sanity/schemas/documents/collections/theme";
import { type SanityTopic } from "@/sanity/schemas/documents/collections/topic";

export type MenuMenu = Record<string, Record<string, object>>;

export type MenuSubLink = SanityTheme | SanityTopic;

type MenuContextValues = {
  isMenuOpen: boolean;
  isMenuVisible: boolean;
  openMenu: () => void;
  closeMenu: () => void;
  toggleMenu: () => void;
  menuExitComplete: () => void;
};

type MenuProviderProps = {
  children: ReactNode;
};

const MenuContext = createContext<MenuContextValues | undefined>(undefined);

export function MenuProvider({ children }: MenuProviderProps) {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);

  const openMenu = () => {
    setIsMenuOpen(true);
    setIsMenuVisible(true);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    if (isMenuOpen) closeMenu();
    else openMenu();
  };

  const menuExitComplete = () => {
    setIsMenuVisible(false);
  };

  return (
    <MenuContext.Provider
      value={{
        isMenuOpen,
        isMenuVisible,
        openMenu,
        closeMenu,
        toggleMenu,
        menuExitComplete,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
}

export function useMenuContext() {
  const menuContext = useContext(MenuContext);

  if (!menuContext) throw new Error("useMenuContext can only be used within <MenuProvider>");

  return menuContext;
}
